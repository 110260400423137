import axios from 'axios'
import Api from './api';
import utils from '../utils/utils';
import configs from '../configs/configs'

// 创建axios
const instance = axios.create({
    baseURL: configs.BASE_URL,    //后台服务地址
    timeout: 6000,   //超时时间
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

// 包裹请求方法的容器
const request = {}

for (let key in Api) {
    let api = Api[key];
    request[key] = async function (
        params,        // 请求参数
        config = {}    // 配置参数
    ) {
        let newParams = {};
        if (!params) {
            params = {};
        }
        params['device_time'] = new Date().getTime();
        params['sign'] = utils.getMd5(JSON.stringify(params) + '123456');
        if (api.method === 'post') {
            newParams = new FormData();
            newParams.set("data", utils.encryptByDES(JSON.stringify(params), configs.DES_KEY, configs.DES_IV_SPEC))
        } else {
            newParams = {};
            newParams.data = utils.encryptByDES(JSON.stringify(params), configs.DES_KEY, configs.DES_IV_SPEC);
        }
        //请求的返回值
        var res = null;
        if (api.method === 'post') {
            res = new Promise((resolve, reject) => {
                instance[api.method](api.url, newParams, config).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
            });
        } else {
            config.params = newParams;
            res = new Promise((resolve, reject) => {
                instance[api.method](api.url, config).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
            });
        }
        return res;
    }
}

// 添加请求拦截器
instance.interceptors.request.use(function (request) {
    // 在发送请求之前做些什么
    return request;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default request;