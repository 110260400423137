// utils.js
import CryptoJS from 'crypto-js'
import Cookies from "vue-cookies";
const utils = {
    /**
    * @name: 自定义toast，在自定义时间消失
    * @param:  data    string  显示的文字
    * @param:  callbackTure    function    点击确定回调函数
    * @param:  time    number  显示时间
    */
    toast(data = '', callbackTure = '') {
        var alert_bg = document.createElement('div');
        var alert_box = document.createElement('div');
        var alert_text = document.createElement('div');
        var textNode = document.createTextNode(data ? data : '')
        // 控制背景样式
        utils.setCss(alert_bg, {
            'position': 'fixed',
            'top': '0',
            'left': '0',
            'right': '0',
            'bottom': '0',
            'z-index': '999999999'
        });
        // 控制 提示框样式
        utils.setCss(alert_box, {
            'width': '100%',
            'max-width': '90%',
            'font-size': '16px',
            'text-align': 'center',
            'border-radius': '15px',
            'position': 'absolute',
            'top': '80%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)'
        });
        if (data) {
            // 控制提示字体样式
            utils.setCss(alert_text, {
                'width': '80%',
                'border-bottom': '1px solid #ddd',
                'padding': '16px 10px',
                'color': 'white',
                'background-color': 'rgba(0, 0, 0, 0.7)',
                'opacity': 1,
                'border-radius': '4px',
                'margin': 'auto',
            });
        }

        // 内部结构套入
        alert_text.appendChild(textNode);
        alert_box.appendChild(alert_text);
        alert_bg.appendChild(alert_box);
        // 整体显示到页面内
        document.getElementsByTagName('body')[0].appendChild(alert_bg);

        setTimeout(function () {
            if (typeof callbackTure === 'function') {
                callbackTure(); //回调
            }
            // 弹窗消失~
            utils.setCss(alert_bg, {
                'display': 'none'
            });
        }, 2000);
    },

    /**
     * @name: 自定义alert(确定，取消)
     * @param:  data    string  显示的文字
     * @param:  callbackTure    function    点击确定回调函数
     * @param:  callbackFalse   function    点击取消回调函数
     */
    alert(data, callbackTure = '', callbackFalse = '') {
        var alert_bg = document.createElement('div');
        var alert_box = document.createElement('div');
        var alert_text = document.createElement('div');
        var alert_btn_true = document.createElement('div');
        var alert_btn_false = document.createElement('div');
        var textNode = document.createTextNode(data ? data : '')
        var btnText_false = document.createTextNode('取 消');
        var btnText_true = document.createTextNode('确 定');
        // 控制背景样式
        utils.setCss(alert_bg, {
            'position': 'fixed',
            'top': '0',
            'left': '0',
            'right': '0',
            'bottom': '0',
            'background-color': 'rgba(0,0,0,.6)',
            'z-index': '999999999'
        });
        // 控制 提示框样式
        utils.setCss(alert_box, {
            'width': '80%',
            'max-width': '80%',
            'font-size': '18px',
            'text-align': 'center',
            'background-color': '#fff',
            'border-radius': '15px',
            'position': 'absolute',
            'top': '40%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)'
        });
        // 控制提示字体样式
        utils.setCss(alert_text, {
            'padding': '32px 15px',
            'border-bottom': '1px solid #ddd'
        });
        // 控制确定按钮样式
        utils.setCss(alert_btn_true, {
            'padding': '10px 0',
            'color': '#007aff',
            'font-weight': '600',
            'cursor': 'pointer',
            'float': 'right',
            'text-align': 'center',
            'width': '49%',
        });
        // 控制取消按钮样式
        utils.setCss(alert_btn_false, {
            'padding': '10px 0',
            'color': '#007aff',
            'font-weight': '600',
            'cursor': 'pointer',
            'float': 'right',
            'text-align': 'center',
            'width': '50%',
            'border-right': '1px solid #CCC',
        });
        // 内部结构套入
        alert_text.appendChild(textNode);
        alert_btn_true.appendChild(btnText_true);
        alert_btn_false.appendChild(btnText_false);
        alert_box.appendChild(alert_text);
        alert_box.appendChild(alert_btn_true);
        alert_box.appendChild(alert_btn_false);
        alert_bg.appendChild(alert_box);
        // 整体显示到页面内
        document.getElementsByTagName('body')[0].appendChild(alert_bg);
        // 确定按钮绑定点击事件
        alert_btn_true.onclick = function () {
            // alert_bg.parentNode.removeChild(alert_bg);
            if (typeof callbackTure === 'function') {
                callbackTure(); //回调
            }
            utils.setCss(alert_bg, {
                'display': 'none'
            });
        }
        // 取消按钮绑定点击事件
        alert_btn_false.onclick = function () {
            if (typeof callbackFalse === 'function') {
                callbackFalse(); //回调
            }
            else if (typeof callbackTure === 'function') {
                callbackTure(); //回调
            }
            utils.setCss(alert_bg, {
                'display': 'none'
            });
        }
    },
    setCss(targetObj, cssObj) {
        var str = targetObj.getAttribute("style") ? targetObj.getAttribute('style') : '';
        for (var i in cssObj) {
            str += i + ':' + cssObj[i] + ';';
        }
        targetObj.style.cssText = str;
    },
    setCookies(key, value) {
        Cookies.set(key, value);
    },
    getCookies(key) {
        return Cookies.get(key);
    },
    encryptByDES(message, key, ivstr) {
        const keyHex = CryptoJS.enc.Utf8.parse(key);
        let srcs = CryptoJS.enc.Utf8.parse(message)
        const encrypted = CryptoJS.TripleDES.encrypt(srcs, keyHex, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: CryptoJS.enc.Utf8.parse(ivstr)
        });
        return encrypted.toString();
    },

    getMd5(str) {
        let Md5Str = CryptoJS.MD5(str).toString()
        return Md5Str;
    },
}
export default utils


Date.prototype.format = function (format) {
    if (!format || format == undefined) {
        format = "yyyy-MM-dd";
    }
    var o = {
        "M+": this.getMonth() + 1, //month
        "d+": this.getDate(), //day
        "h+": this.getHours(), //hour
        "m+": this.getMinutes(), //minute
        "s+": this.getSeconds(), //second
        "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
        S: this.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(format))
            format = format.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return format;
};
