import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import less from 'less'
import router from "./router/index";
import request from '../src/request/request'

const app = createApp(App)
app.config.globalProperties.$request = request
app.use(Vant);
app.use(less)
app.use(router)
app.mount("#app");
