<template>
  <div class="home-page">
    <div class="header-view">
      <img class="header-image" src="../assets/header.jpg" />
      <div class="input-view">
        <img class="search-icon" src="../assets/search_icon.png" />
        <input
          class="input"
          placeholder="请输入快递单号"
          v-model="expressNumber"
          @input="inputChange($event.target.value)"
          @keyup.enter="keySearch()"
        />
        <span
          class="clear"
          v-if="expressNumber"
          @click="expressNumber = ''"
        ></span>
        <div class="search-button" @click="keySearch()">查询</div>
      </div>
    </div>
    <!-- 搜索历史列表 -->
    <div class="history-list" v-if="historyList">
      <div class="title">历史记录</div>
      <div
        class="history-item"
        @click="historyItemClick(item)"
        v-for="(item, index) in historyList"
        :key="index"
      >
        <div class="company-name">{{ item.companyName }}</div>
        <div class="express-number">单号：{{ item.expressNumber }}</div>
      </div>
      <div class="clear-view">
        <span class="clear-button" @click="clearButtonClick()">清空历史</span>
      </div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import utils from "../utils/utils";
import { Toast } from "vant";
export default {
  name: "HomePage",
  setup() {
    const expressNumber = shallowRef("");
    const companyCode = shallowRef("");
    const historyList = shallowRef(null);
    return {
      expressNumber,
      companyCode,
      historyList,
    };
  },
  created() {},
  mounted() {
    let that = this;
    that.getHistoryList();
    window["setCookies"] = (key, value) => {
      that.setCookies(key, value);
    };
  },
  methods: {
    setCookies(key, value) {
      utils.setCookies(key, value);
    },
    isApp() {
      if (utils.getCookies("app")) {
        return true;
      } else {
        return false;
      }
    },
    clearButtonClick() {
      let that = this;
      utils.alert(
        "确定清空历史吗？",
        () => {
          localStorage.removeItem("expressHistory");
          that.getHistoryList();
        },
        () => {}
      );
    },
    inputChange() {},
    keySearch() {
      let that = this;
      if (that.expressNumber && that.expressNumber.length > 0) {
        that.$router.push({
          name: "SearchPage",
          query: {
            companyCode: that.companyCode,
            expressNumber: that.expressNumber,
          },
        });
      } else {
        Toast("请输入快递单号");
      }
      if (that.isApp()) {
        window.location.href = "beido://goSearchPage";
      }
    },
    historyItemClick(item) {
      let that = this;
      that.$router.push({
        name: "SearchPage",
        query: {
          companyCode: item.companyCode,
          expressNumber: item.expressNumber,
        },
      });
      if (that.isApp()) {
        window.location.href = "beido://goSearchPage";
      }
    },
    getHistoryList() {
      let that = this;
      that.historyList = JSON.parse(localStorage.getItem("expressHistory"));
      if (that.historyList && that.historyList.length > 0) {
        that.historyList = that.historyList.reverse();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.home-page {
  background: #f8f8f8;
  min-height: 100vh;
  [v-cloak] {
    display: none;
  }
  .header-view {
    width: 100%;
    .header-image {
      width: 100%;
    }
    .input-view {
      display: inline-flex;
      background: #fff;
      height: 50px;
      border-radius: 8px;
      width: 90%;
      margin-left: 5%;
      box-shadow: 0 1px 2px #ddd;
      margin-top: -20px;
      .search-icon {
        width: 15px;
        height: 15px;
        margin:  17px 17px 17px 15px;
      }
      .input {
        width: 71%;
        line-height: 34px;
        font-size: 13px;
        color: gray;
        border: 0px;
        outline: none;
        border-radius: 8px;
        background: #fff;
      }
      .search-button {
        font-size: 13px;
        line-height: 36px;
        margin-top: 8px;
        margin-left: 10px;
      }
    }
  }
  .history-list {
    margin-top: 20px;
    padding-left: 5%;
    width: 90%;
    .title {
      color: #888;
      font-size: 14px;
    }
    .history-item {
      background: #fff;
      border-radius: 4px;
      padding: 10px;
      margin-top: 10px;
      .company-name {
        font-size: 15px;
      }
      .express-number {
        color: #888;
      }
    }
    .clear-view {
      margin-top: 30px;
      padding-bottom: 60px;
      text-align: center;
      .clear-button {
        color: #fff;
        font-size: 14px;
        padding: 8px 20px;
        background: #387ff2;
        border-radius: 5px;
      }
    }
  }
}
</style>
