import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "../view/Home.vue";
import SearchPage from "../view/Search.vue"
import CompanyPage from "../view/Company.vue"
import CompanyInfoPage from "../view/CompanyInfo.vue"


const routes = [
    { path: '/', component: HomePage },
    { path: "/Search", name: "SearchPage", component: SearchPage },
    { path: "/Company", name: "CompanyPage", component: CompanyPage },
    { path: "/CompanyInfo", name: "CompanyInfoPage", component: CompanyInfoPage },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
