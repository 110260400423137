<template>
  <div class="company-info-page">
    <div class="header-view">
      <div class="company-info">
        <img class="company-logo" :src="getCompanyImage()" />
        <div class="company-name">{{ companyInfo.name }}</div>
      </div>

      <div class="input-view">
        <img class="search-icon" src="../assets/search_icon.png" />
        <input
          class="input"
          type="search"
          placeholder="请输入快递单号"
          v-model="expressNumber"
          @input="inputChange($event.target.value)"
          @keyup.enter="keySearch()"
        />
        <span
          class="clear"
          v-if="expressNumber"
          @click="expressNumber = ''"
        ></span>
        <div class="search-button" @click="keySearch()">查询</div>
      </div>
    </div>

    <div class="tel-view" @click="telButtonClick()" v-if="companyInfo.tel">
      <div class="tel">
        客服电话：
        <span>{{ companyInfo.tel }}</span>
      </div>
      <img src="../assets/tel_icon.png" />
    </div>

    <!-- 公司介绍 -->
    <div class="company-introduction" v-if="companyInfo.info">
      <div class="title">{{ companyInfo.name }}简介</div>
      <div class="introduction">{{ companyInfo.info }}</div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import { Toast } from "vant";
import configs from "../configs/configs";
export default {
  name: "CompanyInfoPage",
  setup() {
    const expressNumber = shallowRef("");
    const companyCode = shallowRef("");
    const companyInfo = shallowRef({});
    return {
      expressNumber,
      companyCode,
      companyInfo,
    };
  },
  created() {
    var that = this;
    that.companyCode = that.$route.query.companyCode;
    that.expressNumber = that.$route.query.expressNumber;
  },
  mounted() {
    let that = this;
    that.getCompanyInfo();
  },
  methods: {
    telButtonClick() {
      let that = this;
      if (that.companyInfo && that.companyInfo.tel) {
        window.location.href = "tel:" + that.companyInfo.tel;
      }
    },
    getCompanyImage() {
      let that = this;
      if (that.companyInfo.img) {
        return configs.BASE_IMAGE_URL + that.companyInfo.img;
      } else {
        return configs.BASE_IMAGE_URL + "default_company.png";
      }
    },
    inputChange() {},
    getCompanyInfo() {
      let that = this;
      var postData = {
        code: that.companyCode,
      };
      that.companyInfo = {};
      that.$request.getCompanyInfo(postData, false).then((res) => {
        if (res && res.code == 200) {
          that.companyInfo = res.data;
        } else {
          Toast(res.msg);
        }
      });
    },
    keySearch() {
      let that = this;
      if (that.expressNumber && that.expressNumber.length > 0) {
        that.$router.push({
          name: "SearchPage",
          query: {
            companyCode: that.companyCode,
            expressNumber: that.expressNumber,
          },
        });
      } else {
        Toast("请输入快递单号");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.company-info-page {
  background: #f8f8f8;
  min-height: 100vh;
  [v-cloak] {
    display: none;
  }
  .header-view {
    width: 100%;
    .company-info {
      text-align: center;
      padding-top: 45px;
      .company-logo {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
      .company-name {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .input-view {
      display: inline-flex;
      background: #fff;
      height: 45px;
      border-radius: 8px;
      width: 90%;
      margin-left: 5%;
      box-shadow: 0 1px 2px #ddd;
      margin-top: 20px;
      .search-icon {
        width: 15px;
        height: 15px;
        margin: 15px;
      }
      .input {
        width: 72%;
        height: 100%;
        line-height: 34px;
        font-size: 13px;
        color: gray;
        border: 0px;
        outline: none;
        border-radius: 8px;
        background: #fff;
      }
      .search-button {
        font-size: 13px;
        line-height: 36px;
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }
  .tel-view {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    background: #fff;
    border-radius: 5px;
    line-height: 50px;
    .tel {
      padding-left: 12px;
    }
    span {
      color: #317ee7;
    }
    img {
      width: 25px;
      height: 25px;
      margin-top: 12px;
      margin-right: 12px;
    }
  }

  .company-introduction {
    margin-top: 20px;
    .title {
      text-align: center;
    }
    .introduction {
      line-height: 20px;
      text-indent: 20px;
      font-size: 14px;
      padding: 10px 15px 60px 10px;
      color: #888;
    }
  }
}
</style>
