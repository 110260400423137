<template>
  <div class="search-page">
    <!-- 搜索 -->
    <div class="header-view">
      <img
        class="back-button"
        src="../assets/back_button.png"
        @click="onBackButtonClick()"
      />
      <div class="input-view">
        <img class="search-icon" src="../assets/search_icon.png" />
        <input
          class="input"
          type="search"
          placeholder="请输入快递单号"
          v-model="expressNumber"
          @input="inputChange($event.target.value)"
          @keyup.enter="keySearch()"
        />
        <span
          class="clear"
          v-if="expressNumber"
          @click="expressNumber = ''"
        ></span>
      </div>
      <div class="search-button" @click="keySearch()">查询</div>
    </div>
    <!-- 搜索列表 -->
    <div class="express-detail">
      <!-- <div class="express-state">
        <span class="state-title">最新状态：</span>
        <span>{{ getStateName() }}</span>
      </div> -->
      <div class="express-company" v-if="dataList && dataList.length > 0">
        <img :src="companyImageUrl" />
        <div class="right-view">
          <div class="company-name">{{ billData.name }}</div>
          <div class="change-company" @click="changeCompany">切换公司></div>
        </div>
      </div>
      <ul v-cloak class="express-list">
        <li class="express-item" v-for="(item, index) in dataList" :key="index">
          <div class="status-time">
            <span class="status">{{ item.status }}</span>
            <span>{{ item.time }}</span>
          </div>
          <div class="context">{{ item.context }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import configs from "../configs/configs";
import utils from "../utils/utils";
import { Toast } from "vant";
export default {
  name: "SearchPage",
  setup() {
    const companyCode = shallowRef("");
    const expressNumber = shallowRef("");
    const companyImageUrl = shallowRef("");
    const billData = shallowRef({});
    const dataList = shallowRef([]);
    return {
      companyCode,
      expressNumber,
      companyImageUrl,
      billData,
      dataList,
    };
  },
  created() {
    var that = this;
    that.companyCode = that.$route.query.companyCode;
    that.expressNumber = that.$route.query.expressNumber;
  },
  mounted() {
    var that = this;
    if (that.expressNumber && that.expressNumber.length > 0) {
      that.expressQuery(that.companyCode, that.expressNumber);
    }
    window["setCookies"] = (key, value) => {
      that.setCookies(key, value);
    };
  },
  methods: {
    setCookies(key, value) {
      utils.setCookies(key, value);
    },
    isApp() {
      if (utils.getCookies("app")) {
        return true;
      } else {
        return false;
      }
    },
    changeCompany() {
      var that = this;
      that.$router.push({
        name: "CompanyPage",
        query: {
          expressNumber: that.expressNumber,
        },
      });
      if (that.isApp()) {
        window.location.href = "beido://goCompanyPage";
      }
    },
    getStateName() {
      let that = this;
      if (that.billData && that.billData.state) {
        switch (that.billData.state) {
          case "0":
            return "在途";
          case "1":
            return "揽收";
          case "2":
            return "疑难";
          case "3":
            return "签收";
          case "4":
            return "退签";
          case "5":
            return "派件";
          case "6":
            return "退回";
          case "7":
            return "转投";
          case "8":
            return "清关";
          case "14":
            return "拒签";
          default:
            return "未知";
        }
      }
    },
    inputChange() {},
    keySearch() {
      let that = this;
      that.expressQuery(that.companyCode, that.expressNumber);
    },
    expressQuery(companyCode, expressNumber) {
      let that = this;
      if(!that.isApp()) {
        return;
      }
      var postData = {
        com: companyCode,
        num: expressNumber,
      };
      that.billData = {};
      that.dataList = [];
      that.$request.expressQuery(postData, false).then((res) => {
        if (res && res.code == 200) {
          that.billData = res.data;
          that.companyImageUrl =
            configs.BASE_IMAGE_URL + that.billData.com + ".png";
          let jsonData = that.billData.data;
          that.dataList = JSON.parse(jsonData);
          if (that.billData.com && that.billData.nu) {
            that.setSearchHistory(
              that.billData.com,
              that.billData.nu,
              that.billData.name
            );
          }
        } else {
          Toast(res.msg);
        }
      });
    },
    setSearchHistory(companyCode, expressNumber, companyName) {
      let expressHistory = JSON.parse(localStorage.getItem("expressHistory"));
      if (expressHistory && expressHistory.length > 0) {
        if (expressHistory.length > 9) {
          expressHistory.splice(0, 1);
        }
        for (let i = 0; i < expressHistory.length; i++) {
          if (expressHistory[i].expressNumber == expressNumber) {
            expressHistory.splice(i, 1);
          }
        }
      } else {
        expressHistory = [];
      }
      let historyItem = {
        companyCode: companyCode,
        expressNumber: expressNumber,
        companyName: companyName,
      };
      expressHistory.push(historyItem);
      localStorage.setItem("expressHistory", JSON.stringify(expressHistory));
    },
    onBackButtonClick() {
      // window.history.back();
      this.$router.back();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.search-page {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  [v-cloak] {
    display: none;
  }
  .header-view {
    display: inline-flex;
    width: 100%;
    height: 52px;
    background: #ebebeb;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
  }

  .input-view {
    display: inline-flex;
    background: #fff;
    height: 36px;
    margin-top: 8px;
    border-radius: 8px;
    width: 79%;
  }

  .back-button {
    width: 14px;
    height: 25px;
    margin: 12px 12px;
  }

  .search-icon {
    width: 16px;
    height: 16px;
    margin: 10px;
  }

  .input {
    width: 79%;
    height: 100%;
    line-height: 34px;
    font-size: 13px;
    color: gray;
    border: 0px;
    outline: none;
    border-radius: 8px;
    background: #fff;
  }

  .search-button {
    font-size: 13px;
    line-height: 36px;
    margin-top: 8px;
    margin-left: 10px;
  }

  .express-detail {
    position: absolute;
    top: 52px;
    width: 100%;
    background: #fff;

    .express-company {
      margin: 10px;
      display: flex;
      img {
        width: 40px;
        height: 40px;
        margin-left: 15px;
        border-radius: 5px;
      }
      .right-view {
        margin-left: 8px;
        .change-company {
          color: #317ee7;
          font-size: 13px;
        }
      }
    }
    .express-state {
      padding-top: 10px;
      color: #317ee7;
      font-size: 15px;
      padding-left: 20px;
      .state-title {
        color: #1b1b1b;
      }
    }
    .express-list {
      margin-bottom: 50px;
      padding: 6px 15px;
      background: #f8f8f8;
      .express-item {
        margin-top: 8px;
        background: #fff;
        padding: 10px 10px;
        border-radius: 8px;
        .status-time {
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          margin-bottom: 10px;
          .status {
            color: #317ee7;
          }
        }
        .context {
          font-size: 14px;
          color: gray;
        }
      }
    }
  }
}
</style>
