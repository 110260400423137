<template>
  <div class="company-page">
    <!-- 搜索 -->
    <div class="header-view">
      <div class="input-view">
        <img class="search-icon" src="../assets/search_icon.png" />
        <input
          class="input"
          type="search"
          placeholder="请输入快递公司名称"
          v-model="keyWord"
          @input="inputChange($event.target.value)"
          @keyup.enter="keySearch()"
        />
        <span class="clear" v-if="keyWord" @click="keyWord = ''"></span>
      </div>
      <div class="search-button" @click="keySearch()">查询</div>
    </div>
    <!-- 快递公司列表 -->
    <div v-cloak class="company-list" v-if="dataList.length > 0">
      <div
        class="company-item"
        @click="companyItemClick(item)"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <img :src="getCompanyImage(item)" />
        <div class="left-view">
          <div class="no-tel-company" v-if="item.tel == null">
            {{ item.name }}
          </div>
          <div class="company-name" v-if="item.tel">{{ item.name }}</div>
          <div class="company-tel" v-if="item.tel">
            服务热线：{{ item.tel }}
          </div>
        </div>
        <div
          v-if="item.tel"
          class="right-view"
          @click.stop="telButtonClick(item)"
        >
          <img src="../assets/tel_icon.png" />
        </div>
      </div>
    </div>
    <div class="no-list" v-if="dataList.length == 0">
      <div>无结果</div>
    </div>
  </div>
</template>

<script>
import { shallowRef } from "@vue/reactivity";
import { Toast } from "vant";
import utils from "../utils/utils";
import configs from "../configs/configs";
export default {
  name: "CompanyPage",
  setup() {
    const keyWord = shallowRef("");
    const dataList = shallowRef([]);
    return {
      keyWord,
      dataList,
    };
  },
  created() {
    var that = this;
    that.expressNumber = that.$route.query.expressNumber;
  },
  mounted() {
    var that = this;
    that.getDataList();
    window["setCookies"] = (key, value) => {
      that.setCookies(key, value);
    };
  },
  methods: {
    setCookies(key, value) {
      utils.setCookies(key, value);
    },
    isApp() {
      if (utils.getCookies("app")) {
        return true;
      } else {
        return false;
      }
    },
    companyItemClick(item) {
      var that = this;
      that.$router.push({
        name: "CompanyInfoPage",
        query: {
          companyCode: item.code,
          expressNumber: that.expressNumber,
        },
      });
      if (that.isApp()) {
        window.location.href = "beido://goCompanyInfoPage";
      }
    },
    telButtonClick(item) {
      if (item && item.tel) {
        window.location.href = "tel:" + item.tel;
      }
    },
    getCompanyImage(item) {
      if (item.img) {
        return configs.BASE_IMAGE_URL + item.img;
      } else {
        return configs.BASE_IMAGE_URL + "default_company.png";
      }
    },
    inputChange() {},
    keySearch() {
      let that = this;
      that.getDataList();
    },
    getDataList() {
      let that = this;
      var postData = {
        name: that.keyWord,
        page: 1,
      };
      that.dataList = [];
      that.$request.getCompanyList(postData, false).then((res) => {
        if (res && res.code == 200) {
          that.dataList = res.data;
        } else {
          Toast(res.msg);
        }
      });
    },
    onBackButtonClick() {
      this.$router.back();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.company-page {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  [v-cloak] {
    display: none;
  }
  .header-view {
    display: inline-flex;
    width: 100%;
    height: 52px;
    background: #ebebeb;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    .back-button {
      width: 14px;
      height: 25px;
      margin: 12px 12px;
    }
    .input-view {
      display: inline-flex;
      background: #fff;
      height: 36px;
      margin-top: 8px;
      margin-left: 4%;
      border-radius: 8px;
      width: 85%;

      .search-icon {
        width: 16px;
        height: 16px;
        margin: 10px;
      }

      .input {
        width: 88%;
        height: 100%;
        line-height: 34px;
        font-size: 13px;
        color: gray;
        border: 0px;
        outline: none;
        border-radius: 8px;
        background: #fff;
      }
    }
    .search-button {
      font-size: 13px;
      line-height: 36px;
      margin-top: 8px;
      margin-left: 10px;
    }
  }
  .company-list {
    position: absolute;
    top: 52px;
    width: 100%;
    background: #ebebeb;
    padding-bottom: 40px;
    .company-item {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      background: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 10px;
      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        border-radius: 5px;
      }
      .left-view {
        margin-left: 8px;
        .company-name {
          color: #317ee7;
          font-size: 15px;
        }
        .company-tel {
          color: grey;
          font-size: 13px;
        }
        .no-tel-company {
          color: #317ee7;
          font-size: 15px;
          margin-top: 8px;
        }
      }
      .right-view {
        position: absolute;
        right: 18px;
        margin-top: 7px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .no-list {
    position: absolute;
    top: 52px;
    width: 100%;
    background: #ffff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
</style>
