// api.js
const Api = {
    //快递单号实时查询
    expressQuery: {
        method: 'post',
        url: 'expressQuery',
    },
    getCompanyList: {
        method: 'post',
        url: 'getCompanyList',
    },
    getCompanyInfo: {
        method: 'post',
        url: 'getCompanyInfo',
    },

}
export default Api
